import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchFromAPI } from '../utils/fetchFromAPI';
import { Videos } from './';

const SearchFeed = () => {
  const [videos, setVideos] = useState([]);
  const { searchTerm } = useParams();

  useEffect(() => {
    fetchFromAPI(`search?part=snippet&q=${searchTerm}`).then((data) =>
      setVideos(data.items)
    );
  }, [searchTerm]);

  return (
    <Box
      p={2}
      sx={{
        overflowY: 'auto',
        height: '100vh',
        flex: 2,
        ml: { xs: 0, md: 20 },
      }}
    >
      <Typography variant="h5" fontWeight="bold" mb={2} sx={{ color: 'white' }}>
        Results for: <span style={{ color: '#ad13c4' }}>{searchTerm}</span>{' '}
        videos
      </Typography>

      <Videos videos={videos} />
    </Box>
  );
};

export default SearchFeed;
