import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import TvIcon from '@mui/icons-material/Tv';
import RadioIcon from '@mui/icons-material/Radio';

export const logo = 'https://i.ibb.co/s9Qys2j/logo.png';

export const categories = [
  { name: 'New', icon: <HomeIcon /> },
  { name: 'Music', icon: <MusicNoteIcon /> },
  { name: 'Education', icon: <SchoolIcon /> },
  { name: 'Podcast', icon: <GraphicEqIcon /> },
  { name: 'Movie', icon: <OndemandVideoIcon /> },
  { name: 'RTBF', icon: <TvIcon /> },
  { name: 'TF1', icon: <TvIcon /> },
  { name: 'SkyRock', icon: <RadioIcon /> },
  { name: 'Gaming', icon: <SportsEsportsIcon /> },
  { name: 'Gaming Assassins Creeed', icon: <SportsEsportsIcon /> },
  { name: 'Crypto', icon: <DeveloperModeIcon /> },
  { name: 'Programming', icon: <CodeIcon /> },
  { name: 'JS Mastery', icon: <CodeIcon /> },
  { name: 'Sonny Sangha', icon: <CodeIcon /> },
  { name: 'Traversy Media', icon: <CodeIcon /> },
  { name: 'Kevin Powell', icon: <CodeIcon /> },
  { name: 'Devaslife', icon: <CodeIcon /> },
  { name: 'Web Dev Simplified', icon: <CodeIcon /> },
  { name: 'Coding', icon: <CodeIcon /> },
  { name: 'ReactJS', icon: <CodeIcon /> },
  { name: 'NextJS', icon: <CodeIcon /> },
  { name: 'Hacking', icon: <CodeIcon /> },
  { name: 'Live', icon: <LiveTvIcon /> },
  { name: 'Fashion', icon: <CheckroomIcon /> },
  { name: 'Beauty', icon: <FaceRetouchingNaturalIcon /> },
  { name: 'Comedy', icon: <TheaterComedyIcon /> },
  { name: 'Las Fierbinti', icon: <TheaterComedyIcon /> },
  { name: 'Sport', icon: <FitnessCenterIcon /> },
  { name: 'Gym', icon: <FitnessCenterIcon /> },
  { name: 'Yoga', icon: <FitnessCenterIcon /> },
  { name: 'Mma', icon: <SportsMmaIcon /> },
  { name: 'KickBoxing', icon: <SportsMmaIcon /> },
];

export const demoChannelUrl = '/channel/UCmXmlB4-HJytD7wek0Uo97A';
export const demoVideoUrl = '/video/GDa8kZLNhJ4';
export const demoChannelTitle = 'JavaScript Mastery';
export const demoVideoTitle =
  'Build and Deploy 5 JavaScript & React API Projects in 10 Hours - Full Course | RapidAPI';
export const demoProfilePicture =
  'http://dergipark.org.tr/assets/app/images/buddy_sample.png';
